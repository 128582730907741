import http from "../../utils/http"
/**
 * 我的等级模块
 */


/**
 * 我拥有的等级        
 */
export function getMyLevelList(params) {
    return http({
        url: "/customerlevel/api/customerlevel/myList",
        data: params,
        forceLogin: true
    })
}



/**
 * 购买插件等级记录   
 */
export function myBuyRecord(params) {
    return http({
        url: "/customerlevel/api/customerlevelOrder/list",
        data: params,
        forceLogin: true
    })
}


/**
 * 客户等级--购买数据获取
 */
export function getCustomerLevel(params) {
    return http({
        url: "/customerlevel/api/customerlevel/list",
        data: params,
        forceLogin: true
    })
}





/**
 * 购买--购买卡 
 */
export function goBuy(params) {
    return http({
        url: "/customerlevel/api/customerlevelOrder/create",
        data: params,
        forceLogin: true
    })
}






//getData












