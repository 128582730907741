<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="my-point" v-loading="loading">
			<div class="member-point"   v-if=" is_bug == 0" >
				<div class="title">我的等级</div>
			</div>
			<div class="ns-member-address-list" v-if="levellist.length > 0  &&  is_bug == 0" >
				<div :class="item.is_expire == 1 && item.expire_time != -1 ? 'text item dueto' : 'text item'" v-for="(item, index) in levellist" :key="index">
					<div class="text-name">
						<span>{{ item.level_name }}</span>
					</div>
					<div :class="item.is_expire == 1 && item.expire_time != -1 ? 'text-content hight2' : 'text-content hight1'">
						<p class="ns-address-detail" v-if="item.expire_time == -1">永久</p>
						<p class="ns-address-detail" v-else>到期时间：{{ item.expire_time }}</p>
						<p class="ns-address-detail " v-if="item.is_expire == 1 && item.expire_time != -1">
							已到期请尽快充值享受福利
							<span class="renewal"   @click="showByg(1)"  >续费</span>
						</p>
					
					</div>
					<div class="text-operation"></div>
				</div>
			</div>

			<!-- 购买模式0关闭  1开启 -->
			<div v-if="is_bug == 0">
				<div class="member-point"><div class="title">购买记录</div></div>
				<div class="detail">
					<el-table :data="buyRecordlist" border>
						<el-table-column prop="order_no" label="订单编号" width="250"></el-table-column>
						<el-table-column prop="create_time" label="创建时间" width="300"></el-table-column>
						<el-table-column prop="title" label="等级名称" width="200"></el-table-column>
						<el-table-column prop="price" label="支付金额" width="250"></el-table-column>
					</el-table>
				</div>
				<div class="pager">
					<el-pagination
						background
						:pager-count="5"
						:total="total"
						prev-text="上一页"
						next-text="下一页"
						:current-page.sync="listInfo.page"
						:page-size.sync="listInfo.page_size"
						@size-change="handlePageSizeChange"
						@current-change="handleCurrentPageChange"
						hide-on-single-page
					></el-pagination>
				</div>
			</div>

			<div v-if="is_bug == 1">
				<div class="member-point"><div class="title">购买会员</div> <button class="primary el-button--primary" @click="promptlyBuy()">立即购买</button>  </div>
				
				
				
				
				<div class="customerLevel-list amendList" v-for="(item, index) in customerLevelList" :key="index">
					<div class="level_name">
						<div class="level-desc">
							<span class="price-num">会员名称:{{ item.level_name }}</span>
							<span class="endTime" v-if="item.expire_time == 0"></span>
							<span class="endTime" v-else-if="item.expire_time == -1">永久有效</span>
							<span class="endTime" v-else>到期时间:{{ item.expire_time }}</span>
						</div>
						<!-- 购买圈与对勾 -->
						<div class="border22 amendSmallB2" v-if="item.expire_time == 0"></div>
						<div class="amendSquareS" v-else-if="is_select == index" @click="select(index, item.expire_time)">
							<!-- <img :src="$img('upload/uniapp/member/index/menu/squareSelect.png')" mode="" class="amendRight"></img> -->
						</div>
						
						
						<div class="border amendBoder" v-else @click="select(index, item.expire_time)"></div>
					</div>

					<div class="price_json_box">
						<div
							class="item-list amendItemList"
							v-for="(item2, index2) in item.price_json"
							:key2="index2"
							:class="is_select == index && is_again_select == index2 && item.is_have_get_forever != 1 && is_dj == 1 ? 'bgActive' : ''"
						>
							<div class="item2_box" v-if="item2.price == 0" @click="zeroChk(index, index2, item, item2, 1)">
								<div class="select">
									<div class="">
										<div class="border2 amendSmallB2" v-if="item.is_have_get_forever == 1"></div>
										<div class="border2 amendSmallB2" v-else-if="item.is_have_get_zero == 1"></div>
										<div
											class="border amendSmallB"
											v-else-if="is_select == index && is_again_select == index2 && item.is_have_get_zero != 1 && is_dj == 1 ? 'bgActive' : ''"
										>
											<img :src="$img('upload/uniapp/member/index/menu/right.png')" mode="" class="amendRight"></img>
										</div>
										<div class="border amendSmallB" v-else></div>
									</div>
									<div class="select-desc">
										<div class="left">
											<div class="title">{{ item2.title }}</div>
										</div>
										<div class="right">
											<span class="price">免费领取</span>
											<span class="day_num">体验期:{{ item2.day_num }}天</span>
										</div>
									</div>
								</div>
							</div>
							<div class="item2_box" v-else-if="item2.price > 0 && item2.day_num != -1" @click="chk(index, index2, item, item2, 1)">
								<div class="select">
									<div class="">
										<div class="border2 amendSmallB2" v-if="item.is_have_get_forever == 1"></div>
										<div class="border amendSmallB" v-else-if="is_select == index && is_again_select == index2">
											<img :src="$img('upload/uniapp/member/index/menu/right.png')" mode="" class="amendRight"></img>
										</div>
										<div class="border amendSmallB" v-else></div>
									</div>
									<div class="select-desc">
										<div class="left">
											<div class="title">{{ item2.title }}</div>
										</div>
										<div class="right">
											<span class="price">¥{{ item2.price }}</span>
											<span class="day_num">体验期:{{ item2.day_num }}天</span>
										</div>
									</div>
								</div>
							</div>
							<div class="item2_box" v-else-if="item2.price > 0 && item2.day_num == -1" @click="chk(index, index2, item, item2, 1)">
								<div class="select">
									<div class="border2 amendSmallB2" v-if="item.is_have_get_forever == 1"></div>
									<div class="border amendSmallB" v-else-if="is_select == index && is_again_select == index2">
										<img :src="$img('upload/uniapp/member/index/menu/right.png')" mode="" class="amendRight"></img>
									</div>
									<div class="border amendSmallB" v-else></div>
									<div class="select-desc">
										<div class="left">
											<div class="title">{{ item2.title }}</div>
										</div>
										<div class="right">
											<div class="price">¥{{ item2.price }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { redRecordList } from '@/api/member/red_record_list';
import { getMyLevelList, myBuyRecord, getCustomerLevel, goBuy } from '@/api/member/my_grades.js';

export default {
	name: 'my_grades',
	components: {},
	data: () => {
		return {
			listInfo: {
				page: 1,
				page_size: 10
			},
			total: 0,
			loading: true,
			yes: true,
			levellist: [],
			buyRecordlist: [],
			customerLevelList: [],
			is_bug: 1, //购买模块
			is_select: 0,
			is_dj: 0,
			is_again_select: 0,
			index: 0,
			index2: 0,
			
			is_select: 0,
			is_dj: 0,
			is_again_select: 0,
			groupId: '', //分组id
			title: '', //套餐标题
			dayNum: '', //套餐天数
			price: '', //套餐价格
			is_have_get_forever: '',
		};
	},
	created() {
		// if(this.$route.query.is_bug==1){
		// 	this.showByg(1);
		// }
		this.showByg(1);
		this.getMyLevelList();
		this.getBuyRecord();
		
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false;
		}, 300);
	},
	methods: {
		
		
		
		
		// 免费领取
		zeroChk(index, index2, item, item2, is_dj = 0) {
			if (item.is_have_get_zero !== 0 || item.is_have_get_forever !== 0) {
				uni.showToast({
					title: '已领取过',
					icon: 'none'
				});
			} else {
				this.is_dj = is_dj;
				this.groupId = item.group_id;
				this.levelId = item.level_id;
				this.title = item.level_name;
				this.dayNum = item2.day_num;
				this.levelName = item.level_name;
				this.price = item2.price;
				this.is_select = index;
				this.is_again_select = index2;
			}
		},
		chk(index, index2, item, item2, is_dj = 0) {
			if (item.is_have_get_forever !== 0) {
				uni.showToast({
					title: '已永久领取',
					icon: 'none'
				});
			} else {
				this.is_dj = is_dj;
				this.groupId = item.group_id;
				this.levelId = item.level_id;
				this.title = item.level_name;
				this.dayNum = item2.day_num;
				this.price = item2.price;
				this.levelName = item.level_name;
				this.is_select = index;
				this.is_again_select = index2;
			}
		},
		select(index, time) {
			if ((time <= time || time == -1) && time != 0) {
				this.$refs.customerLevel.close();
			}
			this.is_select = index;
			this.is_dj = 0; //清除选择红色
			this.is_again_select = 0; //清除选中对勾
			this.levelN = this.customerLevelList[index].level_name;
			this.discountP = this.customerLevelList[index].discount;
		},
		// 购买卡
		promptlyBuy() {
			if (this.groupId != 0) {
				
				 var data={
					group_id: this.groupId,
					level_id: this.levelId,
					title: this.title,
					day_num: this.dayNum,
					price: this.price
				};
				
				goBuy(data)
					.then(res => {
						console.log("goBuy",res);
						if (res.data && res.code == 0) {
							if (this.price == 0) {
								 //重新刷新数据
								 this.is_bug=0;
								 this.getMyLevelList();
								 this.levellist =[];
								 this.getBuyRecord();
								 this.buyRecordlist =[];
							}else{
								this.$router.push({
									path: "/pay",
									query: {
										code: res.data
									}
								})
							}
						} else {
							this.$message.error(err.message);
						}
					})
					.catch(err => {
						console.log(err.message);
					});
			} else {
				this.$message.error("请勾选任一套餐进行购买");
				return false;
			}
		},
		
		
		
		
		//购买模式开启
		showByg(e) {
			this.is_bug = e;
			this.getCustomerLevel();
		},

		//购买客户等级数值准备
		getCustomerLevel() {
			getCustomerLevel()
				.then(res => {
					if (res.data && res.code == 0) {
						this.customerLevelList = res.data.list;
						for (var i = 0; i < this.customerLevelList.length; i++) {
							if (this.customerLevelList[i].is_select == 1) {
								this.is_select = i;
								this.levelN = this.customerLevelList[i].level_name;
								this.discountP = this.customerLevelList[i].discount;
							}
						}
					} else {
						this.$message.error(err.message);
					}
				})
				.catch(err => {
					console.log(err.message);
				});
			    this.loading = false;
		},

		//获取我单前的等级list
		getMyLevelList() {
			getMyLevelList()
				.then(res => {
					if (res.data && res.code == 0) {
						this.levellist = res.data;
					} else {
						this.$message.error(err.message);
					}
				})
				.catch(err => {
					console.log(err.message);
				});
			this.loading = false;
		},




		getBuyRecord() {
			myBuyRecord(this.listInfo)
				.then(res => {
					if (res.code == 0 && res.data) {
						this.buyRecordlist = res.data.list;
						this.total = res.data.count;
						this.buyRecordlist.forEach(item => {
							item.create_time = this.$util.timeStampTurnTime(item.create_time);
						});
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},

		handlePageSizeChange(num) {
			this.listInfo.page_size = num;
			this.getBuyRecord();
		},
		handleCurrentPageChange(page) {
			this.listInfo.page = page;
			this.getBuyRecord();
		}
	}
};
</script>
<style lang="scss" scoped>
.primary{
	    position: absolute;
	    right: 2%;
		border-radius: 5px;
} 	
	
.renewal{
	margin-left: 18%;
	background-color: #ff547b;
	border-color: #ff547b;
	padding: 9px 15px;
	font-size: 12px;
	color: #FFF;
	border-radius: 5px;
}	
//客户等级css
.customerlevel {
	display: flex;
	padding: 20px;
	align-items: center;
	background: #fff;
	line-height: 40px;
	justify-content: space-between;
	.ns-text-color {
		background: burlywood;
		width: 30%;
		margin: auto;
		text-align: center;
		padding: 7px;
		border-radius: 25px;
	}

	.tit {
		color: #999;
		font-size: $ns-font-size-sm;
		margin-right: 10px;
	}
	.box {
		width: 90%;
		font-size: $ns-font-size-sm;
		line-height: inherit;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.iconfont {
		color: #bbb;
		font-size: 28px;
	}
}
.customerlevel.service {
	.box {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		-webkit-box-pack: center;
		overflow: hidden;
		word-break: break-all;
	}
}
.customerLevel-body {
	position: absolute;
	left: 0;
	// height: 65%;
	height: 75%;
	.customerLevel-list {
		padding: 2% 4%;
	}
	.price_json_box {
		// display: flex;
		// flex-wrap: wrap;
		// width: 100%;
		// justify-content: space-between;
		.item-list {
			width: 28%;
			display: inline-block;
			padding: 2%;
			background: azure;
			margin-bottom: 3%;
			height: 150px;
			background: #f8f8f8;
			border-radius: 14px;
		}
		.item2_box {
			.title {
				font-size: 32px;
				font-weight: bold;
				margin-bottom: 10px;
			}
			.price {
				font-size: 32px;
			}
			.day_num {
				color: #ccc;
				font-size: 30px;
				margin-top: -12px;
			}
		}
	}
}

// 新
.amendSquareS {
	width: 60px !important;
	height: 60px !important;
	position: absolute;
	top: 0%;
	right: 0px;
	.amendRight {
		width: 100%;
		height: 100%;
	}
}
.level-desc {
	padding: 4px 12px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	font-size: $ns-font-size-lg;
}

.item2_box {
	width: 100%;
	height: 100%;
}

.amendList {
	margin: 10px;
	border-radius: 10px;
	position: relative !important;
	width: 80%;
	padding: 10px;
	margin: auto;
	border-radius: 5px;
	border: 1px solid #d8d8d8;
	margin-bottom: 25px;
	
}

.select-desc {
	display: flex;
	flex: 1;
	align-items: center;
	margin-left: 5%;
	justify-content: space-between;

	.right {
		width: 210px;
		margin-right: 80px;
		.day_num{
			float: right;
		}
	}
}

.select {
	height: 100%;
	align-items: center;
}

.amendSmallB {
	position: absolute;
	left: 5;
}
.border.amendSmallB{
	left: 11px;
}
.amendBoder {
	position: absolute !important;
	top: 3%;
	right: 3%;
}

.amendItemList {
	display: flex;
	height: 40px !important;
	width: 96% !important;
	border: 1px solid #ccc;
	background-color: #fff !important;
	margin: auto;
}

.endTime {
}

.bgActive {
	background: linear-gradient(to right, #ff547b, #ff547b) !important;
	.select {
		.title {
			color: #fff;
		}
	}

	.price {
		color: #fff;
	}

	.day_num {
		color: #fff !important;
	}
}

.goBuy {
	text-align: center;
	background: linear-gradient(to right, #ff064a, #fd695d) !important;
	margin: 20px 30px;
	border-radius: 20px;
	color: #fff;
}

.level_name {
	display: flex;
	align-items: center;
}

.select {
	display: flex;
	justify-content: space-between;
}

.border {
	width: 20px !important;
	height: 20px !important;
	border-radius: 40px;
	border: 1px solid #ccc;
	position: relative;
	background-color: #fff;

	.amendRight {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

//到期class
.is_expire {
	background: darkgray;
}
.is_expire_text {
	color: red !important ;
	font-weight: 900;
}

// 优惠券弹出层
.goods-coupon-popup-layer {
	background: #fff;
	height: 800px;
	.tax-title {
		text-align: center;
		font-size: $ns-font-size-lg;
		line-height: 120px;
		height: 120px;
		display: block;
		font-weight: bold;
		position: relative;

		text {
			position: absolute;
			float: right;
			right: 22px;
			font-size: 40px;
			font-weight: 500;
		}
	}
	.coupon-body {
		position: absolute;
		left: 0;
		right: 0;
		height: 65%;

		.body-item {
			width: 702px;
			height: 130px;
			margin: 0 auto;
			border: 1px solid #fff;
			margin-bottom: 28px;
			border-radius: 10px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			overflow: hidden;
			padding-right: 18px;
			border: 1px solid rgba(0, 0, 0, 0.1) !important;

			.item-price {
				width: 240px;
				height: 100%;
				border-right: 1px dashed rgba(0, 0, 0, 0.2);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				line-height: 1;
				color: #000;
				.price {
					font-size: $ns-font-size-base;
					line-height: 1;
					.price-num {
						font-size: 48px;
					}
				}
				.sub {
					font-size: 20px;
					color: #000;
					line-height: 1;
					margin-top: 14px;
				}
			}
			.item-info {
				flex: 1;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.info-box {
					height: 100%;
					display: flex;
					flex-direction: column;
					padding-left: 23px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.sub {
						font-size: $ns-font-size-sm;
						color: #000;
						line-height: 1;
						&:nth-child(2) {
							color: #ababab;
							margin-top: 18px;
						}
					}
				}

				.item-btn {
					width: 90px;
					height: 48px;
					color: #fff;
					border-radius: 24px;
					float: right;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: $ns-font-size-sm;
					opacity: 0.7;
					line-height: 1;
				}
			}
		}
		.free_div {
			height: 40px;
		}
		.item {
			overflow: hidden;
			margin: 0 20px 20px;
			border-radius: 12px;
			display: flex;
			&:last-child {
				margin-top: 0;
			}
			.main {
				flex: 1;
				padding: 20px 0 20px 20px;
				.price {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					.money {
						font-size: 48px;
						font-weight: 700;
					}
				}
				.sub {
					font-size: $ns-font-size-sm;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
			.tax-split {
				border: 1px dotted;
				position: relative;
				border-right: 0;
				&::before {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					background: #fff;
					border-radius: 50%;
					left: -5px;
					top: -5px;
					z-index: 1;
				}
				&::after {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					background: #fff;
					border-radius: 50%;
					left: -5px;
					bottom: -5px;
					z-index: 1;
				}
			}
			.tax-operator {
				flex: 0.4;
				text-align: center;
				height: 220px;
				line-height: 220px;
				font-size: $ns-font-size-base;
			}
		}
	}
	.button-box {
		width: 100%;
		position: absolute;
		bottom: 0;
		z-index: 1;
	}
}

.goods-cell {
	display: flex;
	padding: 20px;
	align-items: center;
	background: #fff;
	line-height: 40px;
	justify-content: space-between;

	.tit {
		color: #999;
		font-size: $ns-font-size-sm;
		margin-right: 10px;
	}
	.box {
		width: 90%;
		font-size: $ns-font-size-sm;
		line-height: inherit;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.iconfont {
		color: #bbb;
		font-size: 28px;
	}
}


.goods-cell.service {
	.box {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		-webkit-box-pack: center;
		overflow: hidden;
		word-break: break-all;
	}
}

.ns-member-address-list {
	display: flex;
	flex-wrap: wrap;

	.dueto {
		//background: red;
	}

	.text {
		width: 32%;
		height: 140px;
		margin-right: 2%;
		border-radius: 5px;
		border: 1px solid #d8d8d8;
		margin-bottom: 20px;
		padding: 0 15px;
		box-sizing: border-box;
		.text-name {
			height: 37px;
			line-height: 40px;
			padding: 0 10px;
			border-bottom: 1px solid #eeeeee;
		}

		.text-default {
			display: inline-block;
			margin-left: 10px;
			background: $base-color;
			color: #ffffff;
			width: 35px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			border-radius: 3px;
		}

		.text-content {
		}
		.hight1 p {
			height: 98px;
			line-height: 98px;
		}
		.hight2 p {
			height: 49px;
			line-height: 49px;
		}

		.ns-address-detail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.text-operation {
			// 操作
			text-align: right;

			span {
				margin: 0 5px;
				color: #999999;
				cursor: pointer;
			}

			span:hover {
				color: $base-color;
			}
		}
	}
	.text:nth-child(3n) {
		margin-right: 0;
	}
	.ns-add-address {
		border: 1px dashed #d8d8d8;
		text-align: center;
		color: #999999;
		line-height: 140px;
		cursor: pointer;
	}

	.ns-add-address:hover {
		border-color: $base-color;
		color: $base-color;
	}
}

.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.my-point {
	background: #ffffff;
	padding: 20px;
	.member-point {
		font-size: $ns-font-size-base;
		font-weight: 600;
		margin-bottom: 10px;
		display: flex;
		.to_buy {
			margin-left: 3%;
		}
		.num {
			font-size: 30px;
			font-weight: 600;
		}
	}
	.page {
		display: flex;
		justify-content: center;
		align-content: center;
		padding-top: 20px;
	}
}
</style>
