import http from "../../utils/http"



/**
 * 获取  红包使用记录 
 */
export function redRecordList(params) {
    return http({
        url: "/api/memberaccount/page",
        data: params,
        forceLogin: true
    })
}




